"use client";

import { ReCaptchaProvider } from "next-recaptcha-v3";
import env from "../../../lib/utils/env";

const SPRING_RECAPTCHA_SITE_KEY = env("SPRING_RECAPTCHA_SITE_KEY");

export default function ReCaptchaProviderWrapper({
  children,
}: Readonly<{ children: React.ReactNode }>) {
  return (
    <ReCaptchaProvider
      reCaptchaKey={SPRING_RECAPTCHA_SITE_KEY}
      useRecaptchaNet
      strategy="lazyOnload"
    >
      {children}
    </ReCaptchaProvider>
  );
}
