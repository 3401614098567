/**
 * This file provides an abstraction layer between our feature flag interface and
 * the underlying LaunchDarkly sdk
 */

import env from "@lib/utils/env";
import {
  createLoadLocalOverrides,
  createUseFeatureFlag,
  createUseLocalOverride,
  createUsePushExperimentEvent,
  DISABLE_FEATURE_FLAG_PROVIDER_COOKIE_KEY,
} from "@sourceful/shared-utils/flag-utils";
import { createGuestUserIdFetcher } from "@sourceful/shared-utils/guest-utils";
import { Crawler } from "es6-crawler-detect";
import Cookies from "js-cookie";
const CrawlerDetector = new Crawler();

export const launchDarklyId = env("LAUNCHDARKLY_CLIENTSIDE_SDK_KEY");

const SOURCEFUL_ROOT_DOMAIN = env("ROOT_HOSTNAME");
export const getGuestUserId = createGuestUserIdFetcher(SOURCEFUL_ROOT_DOMAIN);

export const isFeatureFlagProviderDisabled = () => {
  const isBot =
    typeof window !== "undefined" && CrawlerDetector.isCrawler(window?.navigator?.userAgent);

  return (
    isBot ||
    env("DISABLE_LAUNCH_DARKLY") === "true" ||
    Cookies.get(DISABLE_FEATURE_FLAG_PROVIDER_COOKIE_KEY) === "true"
  );
};

export const defaultFlagOverrideLoader = () => {
  let flagOverrides: any = {};

  try {
    const flags = require("./flag-overrides.ts");
    flagOverrides = flags.default;
  } catch (error) {
    // Do not log error - not having an overrides file is OK
  }

  return flagOverrides;
};

export const loadLocalFlagOverrides = createLoadLocalOverrides(defaultFlagOverrideLoader);

export const useLocalOverride = createUseLocalOverride(loadLocalFlagOverrides);

export const useFeatureFlag = createUseFeatureFlag({
  useLocalOverride,
  isFeatureFlagProviderDisabled: isFeatureFlagProviderDisabled,
});

export const usePushExperimentEvent = createUsePushExperimentEvent(loadLocalFlagOverrides);
