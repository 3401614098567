import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";
import env from "../utils/env";

const DATADOG_APPLICATION_ID = env("DATADOG_APPLICATION_ID");
const DATADOG_CLIENT_TOKEN = env("DATADOG_CLIENT_TOKEN");
const DATADOG_SITE = env("DATADOG_SITE");
const DATADOG_SERVICE = env("DATADOG_SERVICE");
const DATADOG_ENV = env("DATADOG_ENV");
const DISABLE_DATADOG = env("DISABLE_DATADOG");
const RELEASE_SHA = env("RELEASE_SHA");

const hasRun = {
  datadog: false,
};

export const isDatadogInitialized = () => {
  return hasRun.datadog;
};

export const useDatadog = () => {
  useEffect(() => {
    if (DISABLE_DATADOG === "true") return;
    if (hasRun.datadog) return;

    if (typeof window !== "undefined") {
      datadogRum.init({
        applicationId: DATADOG_APPLICATION_ID,
        clientToken: DATADOG_CLIENT_TOKEN,
        site: DATADOG_SITE,
        service: DATADOG_SERVICE,
        env: DATADOG_ENV,
        version: RELEASE_SHA,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        defaultPrivacyLevel: "mask-user-input",
        trackSessionAcrossSubdomains: true,
      });

      datadogLogs.init({
        clientToken: DATADOG_CLIENT_TOKEN,
        site: DATADOG_SITE,
        version: RELEASE_SHA,
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        env: DATADOG_ENV,
        service: DATADOG_SERVICE,
        forwardConsoleLogs: ["log", "warn", "error", "debug"],
        trackSessionAcrossSubdomains: true,
      });

      datadogRum.startSessionReplayRecording();
    }

    hasRun.datadog = true;
  }, []);
};
