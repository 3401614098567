import env from "@lib/utils/env";
import Cookies from "js-cookie";
import { useCallback } from "react";
import { COOKIE_DISABLE_TRACKING_KEY } from "../../lib/hooks/useTracking.constants";

const INTERCOM_APP_ID = env("INTERCOM_APP_ID");

const hasRun = {
  intercom: false,
};

export const isTrackingDisabled = () => !!Cookies.get(COOKIE_DISABLE_TRACKING_KEY);

export const useIntercomInit = () => {
  const initialiseIntercom = useCallback(() => {
    if (hasRun.intercom || !INTERCOM_APP_ID) return;

    const scriptIntercomInit = document.createElement("script");
    scriptIntercomInit.id = "intercom-init";
    scriptIntercomInit.defer = false;
    scriptIntercomInit.text = ` window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "${INTERCOM_APP_ID}",
        custom_launcher_selector: ".webchat_widget"
      };

      (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${INTERCOM_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

    document.head.appendChild(scriptIntercomInit);
    hasRun.intercom = true;
  }, []);

  return { isInitialised: hasRun.intercom, initialiseIntercom };
};
