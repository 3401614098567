import Cookies from "js-cookie";
import { v4 as uuid } from "uuid";
import { GUEST_USER_COOKIE } from "./constants";

export const createGuestUserIdFetcher = (cookieDomain: string) => () => {
  const userId = Cookies.get(GUEST_USER_COOKIE);

  if (userId) return userId;

  const newId = uuid();
  Cookies.set(GUEST_USER_COOKIE, newId, {
    expires: 365, // 1 year
    domain: cookieDomain,
  });

  return newId;
};

export * from "./constants";
