import { datadogRum } from "@datadog/browser-rum";
import { useParams, usePathname } from "next/navigation";
import { useEffect } from "react";
import { isDatadogInitialized } from "../../lib/hooks/useDatadog";

/*
  This hook is used to manually track page views by pathname in Datadog which will help in building out various funnel reports that 
  need to group sessions by pathname e.g. /buy/[productMain] and not by the specific url (router.asPath) /buy/mailer-box etc. 
  Datadatog's wildcard matching isn't sufficient so we need to help it out a bit
*/

export const useDatadogPageViews = () => {
  const pathname = usePathname();
  const isInitialised = isDatadogInitialized();
  const params = useParams();

  useEffect(() => {
    if (!isInitialised || !pathname) return;

    let pathnameWithSegments = pathname ?? "";

    if (params) {
      // Need to get the 'pages' router pathname format which isnt the same in app router, got to work for it!
      Object.entries(params).forEach(([key, value]) => {
        pathnameWithSegments = pathnameWithSegments.replace(value.toString(), `[${key}]`);
      });
    }

    if (pathname === pathnameWithSegments) return; // avoid tracking same page name twice if it provides no extra benefit for filtering

    console.debug("useDatadogPageViews startView", { pathname, pathnameWithSegments });

    datadogRum.startView({ name: pathnameWithSegments });
  }, [isInitialised, params, pathname]);
};
